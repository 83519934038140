import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import TopNav from "../menu/TopNav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStore, faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import './NavMenu.css';
import TopLogo from "../menu/TopLogo";

const NavMenu = (props) => {
    const [scroll, setScroll] = React.useState(false);
    React.useEffect(() => {
        window.onscroll = function (e) {
            if((window.pageYOffset == 0) == scroll) {
                setScroll(!scroll);
            }
        };
    }, [scroll]);

    return <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" fixed="top" className={scroll ? "bg-dark-scroll" : ""}>
        <Navbar.Brand className="brand-logo" href="#home">
            <TopLogo />
        </Navbar.Brand>
        {/*<div className="input-group col-md-4 navbasket-collapse search-wrap-s"><Search searchCallback={props.searchCallback}/></div>*/}
        <Navbar.Brand className="navbasket-collapse basket-wrap-s" >
            <Nav className="navbar-custom">
                {/*<Nav.Link href="/basket" ><Cart cartCount={props.cartCount}/></Nav.Link>*/}
            </Nav>
        </Navbar.Brand>
        <Navbar.Toggle className="my-theme"/>
        <Navbar.Collapse className="justify-content-between">
            <TopNav />
        </Navbar.Collapse>
        {/*<div style={{width: '50%'}} className="input-group col-md-4 navbasket-right"><Search searchCallback={props.searchCallback}/></div>*/}
        <Navbar.Brand className="navbasket-right">
            <Nav className="navbar-custom">
                {/*<Nav.Link href="/basket" ><Cart cartCount={props.cartCount}/></Nav.Link>*/}
            </Nav>
        </Navbar.Brand>
    </Navbar>;
}

export default NavMenu;
