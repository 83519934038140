import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import GalleryHome from './components/GalleryHome';
import CookiePolicy from './components/CookiePolicy';
import CardMenu from './components/CardMenu';
import TopLinks from './menu/TopLinks';
import { useCookies } from 'react-cookie';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faDumbbell, faInfo, faClipboardList, faHome, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {useHistory} from "react-router-dom";
import $ from 'jquery';

//https://codepen.io/thrasto/pen/qBVojGz
//https://codepen.io/abhishek/pen/VKmGKY
//https://fontawesome.com/icons?d=gallery&m=free
//https://svgsilh.com/image/3146912.html
//https://codepen.io/clagom/pen/kBaxmW
//https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element

//WATER
//https://codepen.io/knoland/pen/XKxrJb
library.add(fab, faCheckSquare, faCoffee, faDumbbell, faInfo, faClipboardList, faHome, faInfoCircle)

const App = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [showFooter, setShowFooter] = React.useState(true); //SET TO FALSE TO USE INTRO
    const history = useHistory();

    React.useEffect(() => {
    }, [props.policyDisplayed, showFooter]);

    function toggleCookies(enable) {
        if(!enable) {
            for (let cookie in cookies) {
                removeCookie(cookie);
            }
        }

        localStorage.setItem('allowCookies', enable.toString());
    }

    // close nave on body click
    $(document).click(function(e) {
        if (!$(e.target).is('a')) {
            $('.collapse').removeClass('show');
        }
    });

    return (<Layout toggleCookies={toggleCookies} showFooter={showFooter}>
        <TopLinks />
    </Layout>);
};

export default App;
