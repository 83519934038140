import React from 'react';
import { connect } from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import anime from 'animejs';
import * as THREE from 'three';
import CardMenu from './CardMenu';
import Title from "./Title";
import HomeWide from "./HomeWide";
import './HomeStars.css';
import Footer from "./Footer";
const fontJson = require( "../assets/Thasadith_Regular.json" );

const HomeStars = props => {
    React.useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize);
        }
    }, [window.innerWidth])

    //https://codepen.io/russellk/pen/PjKVxw
    //font -- http://gero3.github.io/facetype.js/
    const handleResize = () => {
        (function() {
            var Particles, work;
            Particles = class Particles {
                constructor() {
                    this.move = this.move.bind(this);
                    this.setActors = this.setActors.bind(this);
                    this.addStars = this.addStars.bind(this);
                    this.addKnot = this.addKnot.bind(this);
                    this.addTextPete = this.addTextPete.bind(this);
                    this.getTexture = this.getTexture.bind(this);
                    this.setLighting = this.setLighting.bind(this);
                    this.setStage = this.setStage.bind(this);
                    // @controls = new THREE.OrbitControls( @camera, @renderer.domElement )
                    // @controls.addEventListener( 'change', @render )
                    // @controls.target.set( 0, 0, 0 )
                    this.rotateRadians = this.rotateRadians.bind(this);
                    this.animate = this.animate.bind(this);
                    this.render = this.render.bind(this);
                    this.setStage();
                    this.setLighting();
                    this.setActors();
                    this.animate();
                    this.move();
                    this.render();
                }

                move() {
                    anime({
                        loop: true,
                        targets: this.starSystem.rotation,
                        z: [this.rotateRadians(360), this.rotateRadians(0)],
                        x: [this.rotateRadians(360), this.rotateRadians(0)],
                        y: [this.rotateRadians(360), this.rotateRadians(0)],
                        duration: 20000,
                        easing: "linear"
                    });
                    // anime({
                    //     loop: true,
                    //     targets: this.torusSystem.rotation,
                    //     z: [this.rotateRadians(-360), this.rotateRadians(0)],
                    //     x: [this.rotateRadians(-360), this.rotateRadians(0)],
                    //     y: [this.rotateRadians(-360), this.rotateRadians(0)],
                    //     duration: 30000,
                    //     easing: "linear"
                    // });
                    anime({
                        loop: true,
                        targets: this.meshSystem.rotation,
                        z: [this.rotateRadians(360), this.rotateRadians(0)],
                        x: [this.rotateRadians(360), this.rotateRadians(0)],
                        y: [this.rotateRadians(360), this.rotateRadians(0)],
                        duration: 30000,
                        easing: "linear"
                    });
                    return anime({
                        loop: true,
                        targets: this.sphereSystem.rotation,
                        z: [this.rotateRadians(360), this.rotateRadians(0)],
                        x: [this.rotateRadians(360), this.rotateRadians(0)],
                        y: [this.rotateRadians(360), this.rotateRadians(0)],
                        duration: 30000,
                        easing: "linear"
                    });
                }

                setActors() {
                    this.addKnot();
                    this.addTextPete();
                    return this.addStars();
                }

                addStars() {
                    var i, j, materials, x, y, z;
                    this.geometry = new THREE.BufferGeometry(); //Geometry();
                    materials = new THREE.PointsMaterial({
                        color: 0xffffff,
                        size: 1,
                        transparent: true,
                        blending: THREE.AdditiveBlending,
                        map: this.getTexture(),
                        depthTest: false
                    });
                    const verticesList = [];
                    for (i = j = 0; j <= 1000; i = ++j) {
                        x = Math.random() * 100 - 50;
                        y = Math.random() * 100 - 50;
                        z = Math.random() * 100 - 50;
                        verticesList.push(x);
                        verticesList.push(y);
                        verticesList.push(z);
                    }
                    const vertices = new Float32Array(verticesList);
                    this.geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
                    this.starSystem = new THREE.Points(this.geometry, materials);
                    this.starSystem.sortParticles = true;
                    return this.scene.add(this.starSystem);
                }

                addKnot() {
                    var materials;
                    this.geometry = new THREE.SphereGeometry(1, 0, 0);
                    materials = new THREE.PointsMaterial({
                        color: 0xffffff,
                        size: 1,
                        transparent: true,
                        blending: THREE.AdditiveBlending,
                        map: this.getTexture(),
                        depthTest: false
                    });
                    this.sphereSystem = new THREE.Points(this.geometry, materials);
                    this.sphereSystem.sortParticles = true;
                    this.scene.add(this.sphereSystem);

                    // this.geometry = new THREE.TorusKnotGeometry(5, 10, 400, 1, 4, 3);
                    // materials = new THREE.PointsMaterial({
                    //     color: 0xffffff,
                    //     size: 1,
                    //     transparent: true,
                    //     blending: THREE.AdditiveBlending,
                    //     map: this.getTexture(),
                    //     depthTest: false
                    // });
                    // this.torusSystem = new THREE.Points(this.geometry, materials);
                    // this.torusSystem.sortParticles = true;
                    // return this.scene.add(this.torusSystem);
                }

                addTextPete() {
                    const font = new THREE.Font( fontJson );
                    this.geometry = new THREE.TextGeometry( "texthtjfhgfjhg", {
                        font: font,
                        size: 250,
                        height: 50,
                        curveSegments: 12,
                        bevelThickness: 1,
                        bevelSize: 5,
                        bevelEnabled: false
                    });
                    //var textMaterial = new THREE.MeshPhongMaterial( { color: 0xffffff, specular: 0xffffff });
                    //this.meshSystem = new THREE.Mesh( this.geometry, textMaterial );
                    //this.scene.add( this.meshSystem );

                    this.geometry.computeBoundingBox();
                    var centerOffset = -0.5 * ( this.geometry.boundingBox.max.x - this.geometry.boundingBox.min.x );

                    const pointLight = new THREE.PointLight(0xffffff, 1.5);
                    pointLight.position.set(0, 100, 90);
                    this.scene.add(pointLight); pointLight.color.setHSL(Math.random(), 1, 0.5);
                    //const textMaterials = [ new THREE.MeshPhongMaterial({ color: 0xffffff, flatShading: true }), new THREE.MeshPhongMaterial({ color: 0xffffff }), ];
                    var textMaterials = [
                        new THREE.MeshBasicMaterial( { color: '#ECECEE', overdraw: 0.5 } ),
                        new THREE.MeshBasicMaterial( { color: 0xffffff, vertexColors: THREE.VertexColors } )
                    ];
                    this.meshSystem = new THREE.Mesh( this.geometry, textMaterials );
                    this.scene.add( this.meshSystem );
                }

                getTexture() {
                    var canvas, context, gradient, texture;
                    canvas = document.createElement('canvas');
                    canvas.width = 32;
                    canvas.height = 32;
                    context = canvas.getContext('2d');
                    gradient = context.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 2);
                    gradient.addColorStop(0, 'rgba(255,255,255,1)');
                    gradient.addColorStop(0.2, 'rgba(200,255,255,1)');
                    gradient.addColorStop(0.4, 'rgba(0,0,124,1)');
                    gradient.addColorStop(1, 'rgba(0,0,0,1)');
                    context.fillStyle = gradient;
                    context.fillRect(0, 0, canvas.width, canvas.height);
                    texture = new THREE.Texture(canvas);
                    texture.needsUpdate = true;
                    return texture;
                }

                setLighting() {
                    this.ambientLight = new THREE.AmbientLight("#ffffff", 0.5);
                    return this.scene.add(this.ambientLight);
                }

                setStage() {
                    this.renderer = new THREE.WebGLRenderer({
                        canvas: document.getElementById("particles"),
                        antialias: true
                    });
                    this.renderer.setClearColor("#ffffff");
                    this.renderer.setPixelRatio(window.devicePixelRatio);
                    this.renderer.setSize(window.innerWidth, window.innerHeight);
                    this.renderer.shadowMap.enabled = true;
                    this.scene = new THREE.Scene();
                    // this.scene.background = new THREE.Color( 0xaaaaaa );
                    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
                    return this.camera.position.z = 50;
                }

                rotateRadians(deg) {
                    return deg * (Math.PI / 180);
                }

                animate() {
                    requestAnimationFrame(this.animate);
                    // @controls.update()
                    return this.render();
                }

                render() {
                    return this.renderer.render(this.scene, this.camera);
                }

            };

            work = new Particles();

        }).call(this);
    };

    return (<div className="star-body">
        <canvas className="canvas" id="particles">
            This is a canvas. Sorry your browser does not support this tag
        </canvas>
    </div>);
};

export default HomeStars;