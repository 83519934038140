import React from 'react';
import anime from 'animejs';
import * as THREE from 'three';
import './HomeStars.css';
import Footer from "./Footer";
const fontJson = require( "../assets/Thasadith_Regular.json" );

const HomeLogo = props => {
    React.useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize);
        }
    }, [window.innerWidth])

    //https://codepen.io/russellk/pen/PjKVxw
    //font -- http://gero3.github.io/facetype.js/
    const handleResize = () => {
        class Smoke {
            constructor(options) {
                const defaults = {
                    width: window.innerWidth,
                    height: window.innerHeight
                };

                Object.assign(this, options, defaults);
                this.onResize = this.onResize.bind(this);

                this.addEventListeners();
                this.init();
            }

            init() {
                const { width, height } = this;

                this.clock = new THREE.Clock();

                const renderer = this.renderer = new THREE.WebGLRenderer();

                renderer.setSize(width, height);

                this.scene = new THREE.Scene();
                this.scene.background = new THREE.Color( 0xffffff );
                // const textureLoader = new THREE.TextureLoader();
                // const bgTexture = textureLoader.load("/images/after1.png",
                //     function ( texture ) {
                //         var img = texture.image;
                //         // bgWidth
                //         // bgHeight = img.height;
                //         // resize();
                //     } );
                // this.scene.background = bgTexture;

                const meshGeometry = new THREE.BufferGeometry(200, 200, 200);
                const meshMaterial = new THREE.MeshLambertMaterial({
                    color: 0xaa6666,
                    wireframe: false
                });
                this.mesh = new THREE.Mesh(meshGeometry, meshMaterial);

                this.cubeSineDriver = 0;

                this.addCamera();
                this.addLights();
                // this.addImage();
                this.addParticles();

                //// this.addText();
                this.addBackground();

                document.body.appendChild(renderer.domElement);
            }

            evolveSmoke(delta) {
                const { smokeParticles } = this;

                let smokeParticlesLength = smokeParticles.length;

                while(smokeParticlesLength--) {
                    smokeParticles[smokeParticlesLength].rotation.z += delta * 0.2;
                }
            }

            addLights() {
                const { scene } = this;
                const light = new THREE.DirectionalLight(0xffffff, 1);

                light.position.set(-1, 0, 1);
                scene.add(light);
            }

            addCamera() {
                const { scene } = this;
                const camera = this.camera = new THREE.PerspectiveCamera(75, this.width / this.height, 1, 10000);

                camera.position.z = 1000;
                scene.add(camera);
            }

            addParticles() {
                const { scene } = this;
                const textureLoader = new THREE.TextureLoader();
                const smokeParticles = this.smokeParticles = [];

                // textureLoader.load('https://raw.githubusercontent.com/marcobiedermann/playground/master/three.js/smoke-particles/dist/assets/images/clouds.png',
                textureLoader.load('/images/smokefg.png', texture => {
                    const smokeMaterial = new THREE.MeshLambertMaterial({
                        color: 0xffffff,
                        map: texture,
                        transparent: true
                    });
                    smokeMaterial.map.minFilter = THREE.LinearFilter;
                    const smokeGeometry = new THREE.PlaneBufferGeometry(300, 300);

                    const smokeMeshes = [];
                    let limit = 150;

                    while(limit--) {
                        smokeMeshes[limit] = new THREE.Mesh(smokeGeometry, smokeMaterial);
                        smokeMeshes[limit].position.set(Math.random() * 500 - 250, Math.random() * 500 - 250, Math.random() * 1000 - 100);
                        smokeMeshes[limit].rotation.z = Math.random() * 360;
                        smokeParticles.push(smokeMeshes[limit]);
                        scene.add(smokeMeshes[limit]);
                    }
                });
            }

            addImage() {
                const { scene } = this;

                var loader = new THREE.TextureLoader();
                var material = new THREE.MeshLambertMaterial({
                    map: loader.load('/images/me33.png')
                });

                const textGeometry = new THREE.PlaneBufferGeometry(320, 320);
                var geometry = new THREE.PlaneGeometry(100, 200*.75);
                var mesh = new THREE.Mesh(geometry, material);
                mesh.position.set(0,0,0)
                mesh.position.z = 900;
                scene.add(mesh);
            }

            addText() {
                const { scene } = this;
                const font = new THREE.Font( fontJson );
                this.geometry = new THREE.TextGeometry( "texthtjfhgfjhg", {
                    font: font,
                    size: 800,
                    height: 500,
                    curveSegments: 12,
                    bevelThickness: 1,
                    bevelSize: 5,
                    bevelEnabled: false
                });
                // this.geometry.computeBoundingBox();
                // var centerOffset = -0.5 * ( this.geometry.boundingBox.max.x - this.geometry.boundingBox.min.x );

                const pointLight = new THREE.PointLight(0xffffff, 1.5);
                pointLight.position.set(0, 100, 90);
                scene.add(pointLight); pointLight.color.setHSL(Math.random(), 1, 0.5);
                //const textMaterials = [ new THREE.MeshPhongMaterial({ color: 0xffffff, flatShading: true }), new THREE.MeshPhongMaterial({ color: 0xffffff }), ];
                var textMaterials = [
                    new THREE.MeshBasicMaterial( { color: '#ECECEE', overdraw: 0.5 } ),
                    new THREE.MeshBasicMaterial( { color: 0xffffff, vertexColors: THREE.VertexColors } )
                ];
                var mesh = new THREE.Mesh( this.geometry, textMaterials );
                mesh.position.z = 800;
                scene.add(mesh);
            }

            addBackground() {
                const { scene } = this;
                const textureLoader = new THREE.TextureLoader();
                const textGeometry = new THREE.PlaneBufferGeometry(600, 320);

                textureLoader.load('../assets/images/smokebgwhite.jpg', texture => {
                    const textMaterial = new THREE.MeshLambertMaterial({
                        blending: THREE.AdditiveBlending,
                        color: 0x0ffffff,
                        map: texture,
                        opacity: 1,
                        transparent: true
                    });
                    textMaterial.map.minFilter = THREE.LinearFilter;
                    const text = new THREE.Mesh(textGeometry, textMaterial);

                    text.position.z = 800;
                    scene.add(text);
                });
            }

            render() {
                const { mesh } = this;
                let { cubeSineDriver } = this;

                cubeSineDriver += 0.01;

                mesh.rotation.x += 0.005;
                mesh.rotation.y += 0.01;
                mesh.position.z = 100 + Math.sin(cubeSineDriver) * 500;

                this.renderer.render(this.scene, this.camera);
            }

            update() {
                this.evolveSmoke(this.clock.getDelta());
                this.render();

                requestAnimationFrame(this.update.bind(this));
            }

            onResize() {
                const { camera } = this;

                const windowWidth  = window.innerWidth;
                const windowHeight = window.innerHeight;

                camera.aspect = windowWidth / windowHeight;
                camera.updateProjectionMatrix();

                this.renderer.setSize(windowWidth, windowHeight);
            }

            addEventListeners() {
                window.addEventListener('resize', this.onResize);
            }

        }

        /* app.js */

        const smoke = new Smoke();

        smoke.update();
    };

    return (<div>
        {/*<canvas className="smoke-canvas">*/}
        {/*    This is a canvas. Sorry your browser does not support this tag*/}
        {/*</canvas>*/}
    </div>);
};

export default HomeLogo;