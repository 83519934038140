import React from 'react';
import Paper from "@material-ui/core/Paper";
import FadeIn from "react-fade-in";
import Testimonial from '../components/Testimonial';
import ImageCompare from '../components/ImageCompare';
import {useInView} from "react-intersection-observer";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";

const SectionInsta = (props) => {
    const { ref, inView, entry } = useInView({/* Optional options */ threshold: 0, });
    const [instaLoaded, setInstaLoaded] = React.useState(false);

    React.useEffect(() => {
        if(!instaLoaded) {
            embedSocial(document, "script", "EmbedSocialHashtagScript");
        }
    }, [instaLoaded]);

    const embedSocial = (d, s, id) => {
        let js;
        if (d.getElementById(id)) {return;}
        js = d.createElement(s);
        js.id = id;
        js.src = "https://embedsocial.com/cdn/ht.js";
        d.getElementsByTagName("head")[0].appendChild(js);
        setInstaLoaded(true);

    };


    return (<div className="new-page-section" ref={ref}>
        <FadeIn transitionDuration="200" delay={0} visible={inView ? true : false}>
            <div className="page page-text" style={{margin: '0 auto', justfyContent: 'center'}}>
                <a name="insta"></a>
                <FadeIn transitionDuration="400" visible={inView ? true : false}>
                    <h1 className="formheader">INSTAGRAM</h1>
                </FadeIn>
                <Accordion className="no-borderz" expanded={inView ? true : false}>
                    <AccordionSummary></AccordionSummary>
                    <div className="embedsocial-hashtag" data-ref="090fc290b5b1c7b0e2aa1d252de1449e613dc755"></div>
                </Accordion>
            </div>
        </FadeIn>
    </div>);
};
export default SectionInsta;