import * as React from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const steps = [
    {
        label: 'Complete sign-up form to register your interest',
        description: `This gives me some basic information about you, your goals, and the reasons why you want coaching.`,
    },
    {
        label: 'Quick chat to agree a strategy and a start date',
        description:
            "A quick call/chat to answer any questions you might have, give you a detailed breakdown of the packages I provide, and see if it is a good fit for you. Once everything is agreed you'll get a download link to the app.",
    },
    {
        label: 'Complete a questionnaire which will help me create the best plan for you',
        description: `The questionnaire will cover your goals in detail, dietary restrictions and preferences, injuries, time restraints etc.
              I will then use this information to help create your meal plans and workout plans to best fit you.`,
    },
    {
        label: "Receive workout & meal plans - you're ready to go!",
        description: `Once I have completed creating your mean plans and workout plans based on your questionnaire, I will send them to you through the app which you will receive, and as PDFs in an email.`,
    },
];

export default function VerticalLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ maxWidth: 400, alignContent: 'center', margin: "0 auto"}}>
            <Stepper activeStep={activeStep} orientation="vertical" className="my-theme-stepper">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                index === 3 ? (
                                    <Typography variant="caption">Last step</Typography>
                                ) : null
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? "DONE" : 'Next'}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <div square  sx={{ p: 3 }}>
                    <Typography>Simple as that!</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }} className="reset-button">
                        Restart
                    </Button>
                </div>
            )}
        </Box>
    );
}