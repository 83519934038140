import React from "react";
import './ImageCompare.css';
import $ from 'jquery';

const ImageCompare = (props) => {
    const [loaded, setLoaded] = React.useState(false);
    const divisorRef = React.useRef();
    const handleRef = React.useRef();
    const sliderRef = React.useRef();

    React.useEffect(() => {
    }, []);

    const moveDivisor = () => {
        handleRef.current.style.marginLeft = (sliderRef.current.value - 50) + "%";
        divisorRef.current.style.width = sliderRef.current.value + "%";
        // sliderRef.current.style.marginLeft = (sliderRef.current.value - 50) + "%";

        // handleRef.current.style.marginLeft = ($("#slider").val() - 50) + "%";
        // divisorRef.current.style.width = $("#slider").val() + "%";
        // $("#slider").css("marginLeft", ($("#slider").val() - 50) + "%");
    };

    return (<div className="slide-container">
        <div className="comparison">
            <figure style={{backgroundImage: `url(${props.before})`}}>
                <div ref={handleRef} id="handle"></div>
                <div ref={divisorRef} id="divisor" style={{backgroundImage: `url(${props.after})`}}></div>
            </figure>
            <input type="range" min="0" max="100" ref={sliderRef} id="slider" onInput={moveDivisor} />
        </div>
    </div>);
};
export default ImageCompare;

