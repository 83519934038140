import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import React from 'react';
import FadeIn from "react-fade-in";
import Rating from "@material-ui/lab/Rating";

export default function CommentCarol(props) {
    const items = [
        {name: "Obie O.",
            category: "Personal training",
            text: "Pete is an excellent trainer. I’ve been training with him for close to two years. He’s skilled at understanding my fitness goals and pushing me beyond my limits to achieve them. I would highly recommend him to anyone looking to get/stay fit physically and mentally.",
            rating: 5},
        {name: "Michael R.",
            category: "Personal training",
            text: "So I’ve been training on and off with Pete for over a year and I can honestly say I have never been in better shape.when training together there is always extra motivation to push and work as hard as Pete to see the results. I understand now that it’s consistency and working with Pete this is what you get. 100% dedication from him however you get what you put in.. Sign me up any day for another 10 Sessions prior to that holiday pump we all desire.",
            rating: 5},
        {name: "Reece H.",
            category: "Online coaching",
            text: "Peter is a great P.T, he has excellent knowledge of exercise and nutrition and tailors his programs to suit individual needs. Friendly, Patient and very effective. As a hard-gainer, ive always struggled to put on and maintain muscle mass. Peter understood this and took a different approach to most trainers ive worked with in the past. Thanks to his exercise and nutrition plan ive not only been able develop muscle mass but also solid routine that can take me further towards my goal. Would definitely recommend.",
            rating: 5},
    ];

    React.useEffect(() => {
    }, []);

    return (<FadeIn transitionDuration="200" delay="400"><Carousel variant="light" className="my-theme-center-text">
        {items.map(item =>
            <Carousel.Item>
                <h5>{item.name}</h5>
                <p className="comment-p"><FormatQuoteIcon/>{item.text}<FormatQuoteIcon/></p>
                <Carousel.Caption>
                    <Rating name="read-only" value={item.rating} readOnly />
                    <p><b>{item.category}</b></p>
                </Carousel.Caption>
            </Carousel.Item>
        )}
    </Carousel></FadeIn>);
}
