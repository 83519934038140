import React from "react";
import FadeIn from "react-fade-in";
import {useHistory} from "react-router-dom";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";
import {faAt, faPencilAlt, faFlag } from "@fortawesome/free-solid-svg-icons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
//import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import {IconFlagUK} from 'material-ui-flags';
// import { NR } from 'country-flag-icons/react/3x2'
import { INSTA_URL, TIKTOK_URL, BRAND_NAME, EMAIL } from '../menu/Consts';

const Footer = (props) => {
    const history = useHistory();
    const [flagVisible, setFlagVisible] = React.useState(false);
    const goTo = (link) => {
        history.push(link);
    }

    React.useEffect(() => {
        if(window.location.pathname ==  "/" ||
            window.location.pathname ==  "/n" ||
            window.location.pathname ==  "/e" ||
            window.location.pathname ==  "/home" ||
            window.location.pathname ==  "/thankyou" ||
            window.location.pathname ==  "/takk") {
            setFlagVisible(false); //SET to true if we want to display language flag
        } else {
            setFlagVisible(false);
        }
    }, [flagVisible])

    const goToExternal = (link) => {
        window.open(link);
    }

    const isEnglish = () => {
        if(localStorage.getItem("language") != null) {
           if(localStorage.getItem("language") == "N") {
               return false;
           }
        }

        return true;
    }

    const setLanguage = () => {
        // if(localStorage.getItem("language") != null) {
        //     if(localStorage.getItem("language") == "N") {
        //         localStorage.setItem("language", "E");
        //         return;
        //     }
        // }
        //
        // localStorage.setItem("language", "N");
    }

    const getFlagUrl = () => isEnglish() ? "./images/england.png" : "./images/norway.png";



    return (
        <Container className="second-bcol footer-container">
            {/*<Row><img className="logo-font" style={{ marginBottom: '10px', height: "50px"}}src="/images/ph_gains_logo_white.png" /></Row>*/}
            <Row className="wide-footer-links">
                <Col><a className="my-theme-color put-right" href="/cookiepolicy">Cookie Policy</a></Col>
                <Col><a className="my-theme-color put-left" href="/terms">Terms & Conditions</a></Col>
            </Row>
            <Row className="small-footer-links center-content">
                <Col><a className="my-theme-color" href="/cookiepolicy">Cookie<br/>Policy</a></Col>
                <Col><a className="my-theme-color" href="/terms">Terms &<br/>Conditions</a></Col>
            </Row>
            {flagVisible ? <Row>
                <div className="center-content process-link" onClick={() => setLanguage()}>
                    <small>{isEnglish() ? "EN" : "N"} </small><img height="14px" src={getFlagUrl()} alt="language"/>
                </div>
            </Row> : null}
            <Row className="wide-footer-buttons">
                <Col><p className="put-right"><FadeIn delay={1200}>
                    <Button className="footer-button"
                            onClick={() => goToExternal(INSTA_URL)}
                            variant="outline-light">INSTAGRAM <FontAwesomeIcon icon={faInstagram}/></Button></FadeIn></p></Col>
                <Col><p className="center-content"><FadeIn delay={1200}>
                    <a href="#home"><Button className="footer-button"
                           variant="outline-light">SIGN UP <FontAwesomeIcon icon={faPencilAlt}/></Button></a></FadeIn></p></Col>
                <Col><p className="put-left"><FadeIn delay={1200}>
                    <Button className="footer-button" onClick={() => goToExternal(TIKTOK_URL)}
                                               variant="outline-light">TIKTOK <FontAwesomeIcon icon={faTiktok}/></Button></FadeIn></p></Col>
                {/*<Col><p className="put-left"><FadeIn delay={1200}>*/}
                {/*    <a href="#contact"><Button className="footer-button"*/}
                {/*            variant="outline-light">EMAIL <FontAwesomeIcon icon={faAt}/></Button></a></FadeIn></p></Col>*/}
            </Row>
            <Row className="small-footer-buttons">
                <Col><p className="put-right"><FadeIn delay={1200}>
                    <Button className="footer-button"
                            onClick={() => goToExternal(INSTA_URL)}
                            variant="outline-light"><FontAwesomeIcon icon={faInstagram}/></Button></FadeIn></p></Col>
                <Col><p className="center-content"><FadeIn delay={1200}>
                    <a href="#home"><Button className="footer-button"
                            variant="outline-light"><FontAwesomeIcon icon={faPencilAlt}/></Button></a></FadeIn></p></Col>
                <Col><p className="put-left"><FadeIn delay={1200}>
                    <Button className="footer-button"
                            onClick={() => goToExternal(TIKTOK_URL)}
                            variant="outline-light"><FontAwesomeIcon icon={faTiktok}/></Button></FadeIn></p></Col>
                {/*<Col><p className="put-left"><FadeIn delay={1200}>*/}
                {/*    <Button className="footer-button"*/}
                {/*            onClick={() => goTo("/contact")}*/}
                {/*            variant="outline-light"><FontAwesomeIcon icon={faAt}/></Button></FadeIn></p></Col>*/}
            </Row>
            <Row className="center-content">
                <Typography className="center-me" variant="body2" color="textSecondary" align="center">
                    {'Copyright © '}
                    <Link color="inherit" href="https://material-ui.com/">
                        {BRAND_NAME}
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography></Row></Container>
    );
};
export default Footer;


