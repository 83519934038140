import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CommentCarol from "../components/CommentCarol";
import React from 'react';
import FadeIn from "react-fade-in";
import './Testimonial.css';

const useStyles = makeStyles({
    root: {
        minWidth: '90%',
        maxWidth: '90%',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
        color: 'grey'
    },
});

export default function Testimonial(props) {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    React.useEffect(() => {
        // if(props.term != "" && (props.name.includes(props.term) ||
        //     props.category.includes(props.term) ||
        //     props.text.includes(props.term))) {
        //     props.searchMatchCallback("Testimonials", props.term, true);
        // } else {
        //     props.searchMatchCallback("Testimonials", props.term, false);
        // }
    }, []);

    return (<FadeIn transitionDuration="200" delay="400"><Card className={classes.root}>
        <CardContent className="quote-style">
            <Typography variant="h5" component="h2">{props.name}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">{props.category}
            </Typography>
            <blockquote lang="en" style={{fontSize: 'small'}}>props.text}
            </blockquote>
            <div>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating name="read-only" value={props.rating} readOnly />
                </Box>
            </div>
        </CardContent>
    </Card>
    </FadeIn>);
}
