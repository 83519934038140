import React from "react";
import SignupLenus from "./SignupLenus"
import {Nav, Navbar} from "react-bootstrap";

const SectionHome = props => {
    React.useEffect(() => {
    }, [])

    return (<React.Fragment>
        <div className={'home-text home-section fill-parent'} >
            <a name="home"></a>
            <SignupLenus />
        </div>
    </React.Fragment>);
};

export default SectionHome;