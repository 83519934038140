import React from 'react';
import Paper from "@material-ui/core/Paper";
import FadeIn from "react-fade-in";
import Testimonial from '../components/Testimonial';
import ImageCompare from '../components/ImageCompare';
import {useInView} from "react-intersection-observer";
import Carousel from 'react-material-ui-carousel'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Grid from "@material-ui/core/Grid";
import CommentCarol from "../components/CommentCarol";
import ResponsiveGallery from "../components/ResponsiveGallery";

const SectionTrans = (props) => {
    const { ref, inView, entry } = useInView({/* Optional options */ threshold: 0, });
    const title = "TRANSFORMATIONS";

    React.useEffect(() => {
    }, []);


    return (<FadeIn transitionDuration="200" delay="200" visible={inView ? true : false}>
        <div ref={ref} className="small-page" style={{margin: '0 auto', justfyContent: 'center'}}>
            <a name="transformations"></a>
            <FadeIn className="new-page-section" transitionDuration="400" delay="200" visible={inView ? true : false}>
                <h1 className="formheader">{title}</h1></FadeIn>
            <FadeIn transitionDuration="600" visible={inView ? true : false}><h4 className="formsubheader-small">From only a few of my coaching clients</h4></FadeIn>

            <FadeIn transitionDuration="400" delay="300" visible={inView ? true : false}>
                {/*<ResponsiveGallery/>*/}
                <div id="container-trans">
                <div id="container-shadow"><ImageCompare before="/images/after1.png" after="/images/before1.png"/></div>
                    <div id="container-shadow"><ImageCompare before="/images/after2.png" after="/images/before2.png"/></div>
                    <div id="container-shadow"><ImageCompare before="/images/after3.png" after="/images/before3.png"/></div>
                    <div id="container-shadow"><ImageCompare before="/images/After4.jpg" after="/images/Before4.jpg"/></div>
                    <div id="container-shadow"><ImageCompare before="/images/After5.jpg" after="/images/Before5.jpg"/></div>
                    <div id="container-shadow"><ImageCompare before="/images/After6.jpg" after="/images/Before6.jpg"/></div>
                    <div id="container-shadow"><ImageCompare before="/images/After7.jpg" after="/images/Before7.jpg"/></div>
                    <div id="container-shadow"><ImageCompare before="/images/After8.jpg" after="/images/Before8.jpg"/></div>
                    <div id="container-shadow"><ImageCompare before="/images/After9.jpg" after="/images/Before9.jpg"/></div>

                </div>
                {/*<div className="compare-container">*/}
                {/*    <div style={{backgroundColor: 'red'}}>*/}
                {/*        <ImageCompare before="/images/after1.png" after="/images/before1.png"/></div>*/}
                {/*    /!*<ImageCompare before="/images/after1.png" after="/images/before1.png"/>*!/*/}
                {/*    /!*<Grid container spacing={3}>*!/*/}
                {/*    /!*    <Grid item xs={4}><ImageCompare before="/images/after1.png" after="/images/before1.png"/></Grid>*!/*/}
                {/*    /!*    <Grid item xs={4}><ImageCompare before="/images/after2.png" after="/images/before2.png"/></Grid>*!/*/}
                {/*    /!*    <Grid item xs={4}><ImageCompare before="/images/after3.png" after="/images/before3.png"/></Grid>*!/*/}
                {/*    /!*</Grid>*!/*/}
                {/*</div>*/}
            </FadeIn>
            <FadeIn transitionDuration="600" delay="400" visible={inView ? true : false}>
            </FadeIn>
        </div>
    </FadeIn>);
};
export default SectionTrans;