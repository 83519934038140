import React from 'react';
import $ from "jquery";
import StandardSection from "./StandardSection";
import './SectionGroup.css';
import {Card, CardGroup} from "react-bootstrap";

import {useInView} from "react-intersection-observer";

const SectionGroup = (props) => {
    const { ref, inView } = useInView({/* Optional options */ threshold: 0, });
    const [bannerLoaded, setBannerLoaded] = React.useState(false);



    return (
        <div className="meet-container"
             ref={ref} style={{marginTop: `${props.marginTop}`}}>
            <CardGroup>
                <Card className="no-room section">
                    <StandardSection title="First section">{props.children[0]}</StandardSection>
                    {props.children.length > 2 ? <StandardSection title="Second section">{props.children[1]}</StandardSection> : null}
                </Card>
                {/*<Card className="no-room section meet">*/}
                {/*    {props.children.length > 2 ? <StandardSection title="Third section">{props.children[2]}</StandardSection> :*/}
                {/*        <StandardSection title="Second section">{props.children[1]}</StandardSection>}*/}
                {/*</Card>*/}
            </CardGroup>
        </div>
    );
};
export default SectionGroup;
