import React from "react";
import {Nav, Navbar} from "react-bootstrap";

const TopNav = props => {
    React.useEffect(() => {
    }, [])

    return (<React.Fragment>
        <Nav className="navbar-custom">
            <Nav.Link className="my-theme" href="#home">SIGN UP</Nav.Link>
            <Nav.Link className="my-theme" href="/about">ABOUT</Nav.Link>
            <Nav.Link className="my-theme" href="/coaching">COACHING</Nav.Link>
            <Nav.Link className="my-theme" href="/transformations">TRANSFORMATIONS</Nav.Link>
            <Nav.Link className="my-theme" href="/app">APP</Nav.Link>
            <Nav.Link className="my-theme" href="/instagram">INSTAGRAM</Nav.Link>
            <Nav.Link className="my-theme" href="/testimonials">TESTIMONIALS</Nav.Link>
        </Nav>
    </React.Fragment>);
};

export default TopNav;