import React, {useMemo} from 'react';
import Paper from '@material-ui/core/Paper';
import FadeIn from "react-fade-in";
import './Signup.css';
import VideoEmbed from "../components/VideoEmbed";

const ThankYou = (props) => {
    const header = "THANK YOU FOR YOUR INQUIRY";
    const header2 = "MY ASSISTANT WILL BE IN TOUCH.";
    const info = "What happens next:";
    const headern = "TAKK FOR DIN INTERESSE";
    const header2n = "MIN ASSISTENT VIL TA KONTAKT SNAREST.";
    const infon = "Hva skjer nå:";

    React.useEffect(() => {
    }, []);

    const isEnglish = () => {
        if(localStorage.getItem("language") != null) {
            if(localStorage.getItem("language") == "N") {
                return false;
            }
        }

        return true;
    };

    return (
        <FadeIn transitionDuration="200" delay={0}>
            <div className="separate-page">
                <div>
                    <FadeIn transitionDuration="400" visible={true}><h1 className="formheader" >
                        {isEnglish() ? header : headern}</h1></FadeIn>
                    <FadeIn transitionDuration="400" visible={true}><h2 className="formsubheader">
                        {isEnglish() ? header2 : header2n}</h2></FadeIn>
                    <FadeIn transitionDuration="600" visible={true}><p>
                        {isEnglish() ? info : infon}</p></FadeIn>
                    <div className="signup-div"  style={{margin: '0 auto', justfyContent: 'center'}}>
                        <div className="center-content opage">
                            <div className="center-content signup-container" style={{color: 'white !important'}}>
                                <FadeIn transitionDuration="600" delay="400">
                                    {isEnglish() ?
                                        <iframe src="https://drive.google.com/file/d/17-EiE2Ypz9V0cDbUICE9-JMEJDhG1Rr1/preview" width="640" height="480" allow="autoplay"></iframe> :
                                        <iframe
                                            src="https://drive.google.com/file/d/14AwVwPwRkwDu-FRmNXUwuyV6KI1sUUWh/preview"
                                            width="640" height="480" allow="autoplay"></iframe>
                                    }
                                {/*<VideoEmbed*/}
                                {/*    width=""*/}
                                {/*    playing={true}*/}
                                {/*    loop={false}*/}
                                {/*    videoUrl={isEnglish() ?*/}
                                {/*        "https://drive.google.com/uc?export=download&id=1vrPT7mi474ZupEx3_0SSNz6q0RVWVIZ3" :*/}
                                {/*        "https://drive.google.com/uc?export=download&id=14AwVwPwRkwDu-FRmNXUwuyV6KI1sUUWh"} />*/}
                                </FadeIn>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div></FadeIn>
    );
};
export default ThankYou;
