import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import FadeIn from "react-fade-in";
import {useInView} from "react-intersection-observer";
import './StandardSection.css';

const StandardSection = props => {
    const { ref, inView } = useInView({/* Optional options */ threshold: 0, });

    return (
        <Card className="standard-section fill-parent">
            {props.children}
        </Card>
    );
}

export default StandardSection;