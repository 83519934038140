import React from 'react';
import SectionGroup from "./SectionGroup";
import { useHistory } from "react-router-dom";
import './Home.css';
import SectionHome from "../menu/SectionHome";
import Intro from "./Intro";
import SectionAbout from "../menu/SectionAbout";
import SectionServices from "../menu/SectionServices";
import SectionTest from "../menu/SectionTest";
import SectionInsta from "../menu/SectionInsta";
import SectionApp from "../menu/SectionApp";
import SectionBannerRight from "../menu/SectionBannerRight";
import axios from "axios";
const HomeAd = props => {
    const history = useHistory();
    const [addBackground, setAddBackground] = React.useState(window.innerWidth < 1000);
    const [country, setCountry] = React.useState("");
    const [continent, setContinent] = React.useState("");

   React.useEffect(() => {
       if(country === "") {
           let currentCountry = getGeoInfo();
           setCountry(currentCountry);
           if(currentCountry == "Norway") {
               localStorage.setItem("language", "N");
           } else {
               localStorage.setItem("language", "E");
           }
       }

       if(continent == "SA" ||
           country == "India" ||
           country == "Brunei" ||
           country == "Cambodia" ||
           country == "Indonesia" ||
           country == "Malaysia" ||
           country == "Singapore" ||
           country == "Thailand" ||
           country == "Thailand" ||
           country == "Pakistan" ||
           country == "Romania" ||
           country == "Moldova" ||
           country == "Lithuania" ||
           country == "Latvia" ||
           country == "Kosovo" ||
           country == "Hungary" ||
           country == "Estonia" ||
           country == "Croatia" ||
           country == "Bulgaria" ||
           country == "Albania" ||
           country == "Poland" ||
           country == "Serbia" ||
           country == "Slovakia" ||
           country == "Ukraine" ||
           country == "Albania") {
           if(window.location.pathname ==  "/") {
               history.push("/offers");
           }
       }

       window.addEventListener('resize', handleResize);
       return _ => {
           window.removeEventListener('resize', handleResize);
       }
   }, [window.innerWidth, addBackground, continent, country])

    const handleResize = () => {
       if(window.innerWidth < 1000) {
           if(!addBackground) {
               setAddBackground(true);
           }
       } else {
           if(addBackground) {
               setAddBackground(false);
           }
       }
    };

    const getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                setContinent(data.continent_code);
                return data.country_name;
            })
            .catch((error) => {
                console.log(error);
                return "United Kingdom";
            });
    };

    return (<React.Fragment>
            <React.Fragment>
        <SectionGroup marginTop="0px" showBanner={!addBackground}>
        <SectionHome marginTop="150px"/>
            <div></div>
    </SectionGroup>
            </React.Fragment>
    </React.Fragment>);
};

export default HomeAd;
