import React, {useMemo} from 'react';
import Avatar from '@material-ui/core/Avatar';
import FadeIn from "react-fade-in";

import '../components/Contact.css';
import {useInView} from "react-intersection-observer";


const SectionAbout = (props) => {
    const { ref, inView, entry } = useInView({/* Optional options */ threshold: 0, });
    const background =  `Fitness has always been a part of my life in one way or another.
                        At an early age I partook in athletics, played football & basketball, and start using the
                        gym as soon as I was old enough.
                        Straight away I fell in love with pushing my body to its natural limits, motivated by the
                        results I saw along the way.
                        After finding myself in the gym almost every day for over 10 years, I decided to take my passion
                        for fitness to the next level by qualifying as a level 3 PT.
                        Since then I have never looked back! I love training people from all walks of life and joining
                        them on their journey to reach their fitness goals and beyond.
                        That to me is just as rewarding to me as reaching my own!`;

    const mission =  `To help people reach their fitness goals and become the best version possible of themselves.
                        Most people don't know how far they can go until they start their journey, and experience the
                        progress and the motivation that comes with it, I have been there!
                        I want to help you unlock your fitness potential, you might just surprise yourself with the
                        levels you can reach with the right coaching,
                        whether your goal is building muscle, toning, increasing your general fitness, or all the
                        above!`;

    React.useEffect(() => {
    }, []);

    return (
        <FadeIn transitionDuration="200" delay={0} visible={inView ? true : false}>
            <div ref={ref} style={{margin: '0 auto', justfyContent: 'center'}}>
                <div className="new-page-section center-content page-text" style={{paddingLeft: '2vw', paddingRight: '2vw', color: 'black'}}>
                    <a name="about"></a>
                    <FadeIn transitionDuration="400" visible={inView ? true : false}><h1 className="formheader">ABOUT ME</h1></FadeIn>
                    <FadeIn transitionDuration="500" visible={inView ? true : false}>
                        <div className='center-content'><Avatar
                        alt="PH Gains"
                        src="/images/avatar.jpg"
                        sx={{ width: 56, height: 56 }}/></div>
                    </FadeIn>
                    <FadeIn transitionDuration="600" visible={inView ? true : false}><h2 className="formsubheader">BACKGROUND</h2></FadeIn>
                    <FadeIn transitionDuration="800" visible={inView ? true : false}>
                        <p>{background}</p>
                    </FadeIn>
                    <FadeIn transitionDuration="600" visible={inView ? true : false}><h2 className="formsubheader">MY MISSION</h2></FadeIn>
                    <FadeIn transitionDuration="800" visible={inView ? true : false}>
                        <p>{mission}</p>
                    </FadeIn>
                </div>
                <div></div>
            </div></FadeIn>
    );
};
export default SectionAbout;
