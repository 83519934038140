import React from 'react';
import FadeIn from "react-fade-in";
import {useInView} from "react-intersection-observer";
import CommentCarol from "../components/CommentCarol";

const SectionTest = (props) => {
    const { ref, inView, entry } = useInView({/* Optional options */ threshold: 0, });
    const title = "TESTIMONIALS";

    React.useEffect(() => {
    }, []);


    return (<FadeIn transitionDuration="200" delay="200" visible={inView ? true : false}>
        <div ref={ref} className="small-page" style={{margin: '0 auto', justfyContent: 'center'}}>
            <a name="testimonials"></a>
            <FadeIn className="new-page-section center-content page-text" transitionDuration="400" delay="200" visible={inView ? true : false}>
                <h1 className="formheader">{title}</h1></FadeIn>
            <CommentCarol />
        </div>
    </FadeIn>);
};
export default SectionTest;