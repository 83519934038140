import React from "react";
import { Route } from 'react-router';
import Home from "../components/Home";
import HomeAd from "../components/HomeAd";
import GalleryHome from "../components/GalleryHome";
import CookiePolicy from "../components/CookiePolicy";
import Terms from "../components/Terms";
import OfferForm from "../menu/OfferForm";
import Contact from "../components/Contact";
import ThankYou from "./ThankYou";
import SectionAbout from "./SectionAbout";
import SectionServices from "./SectionServices";
import SectionApp from "./SectionApp";
import SectionInsta from "./SectionInsta";
import SectionTest from "./SectionTest";
import SectionTrans from "./SectionTrans";

const TopLinks = props => {
    React.useEffect(() => {
    }, [])

    return (<React.Fragment>
        {/*<Route exact path='/' component={Home} />*/}
        <Route exact path='/' render={() => <Home />} />
        {/*<Route exact path='/n' render={() => <Home />} />*/}
        {/*<Route exact path='/e' render={() => <Home />} />*/}
        <Route path='/cookiepolicy' render={() => <CookiePolicy />} />
        <Route path='/terms' render={() => <Terms />} />
        <Route path='/offers' render={() => <OfferForm />} />
        <Route path='/register' render={() => <Home />} />
        <Route path='/v2' render={() => <HomeAd />} />
        <Route path='/thankyou' render={() => <ThankYou />} />
        <Route path='/contact' render={() => <Contact />} />

        <Route path='/about' render={() => <SectionAbout />} />
        <Route path='/instagram' render={() => <SectionInsta />} />
        <Route path='/app' render={() => <SectionApp />} />
        <Route path='/testimonials' render={() => <SectionTest />} />
        <Route path='/coaching' render={() => <SectionServices />} />
        <Route path='/transformations' render={() => <SectionTrans />} />
    </React.Fragment>);
};

export default TopLinks;