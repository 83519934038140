import React from 'react';
import anime from 'animejs';
import './Title.css';
const Title = props => {
    React.useEffect(() => {
        // var opac = anime({
        //     targets: '.letter',
        //     opacity:1,
        //     scale:1,
        //     easing:'easeInBounce',
        //     delay: function(el, index) {
        //         return index * 80;
        //     },
        //     direction: 'alternate',
        //     loop: true
        // });
        anime.timeline({loop: false, direction: 'alternate'})
            .add({
                targets: '.letter',
                opacity:1,
                scale:1,
                easing:'easeInBounce',
                delay: function(el, index) {
                    return index * 150;
                }});
    }, [])

    return (<div className="title-container center-content"><div className="name my-theme">
        {props.title.split("").map(letter => <div className="letter">{letter}</div>)}
    </div></div>);
};

export default Title;