import React, {useMemo} from 'react';
import {useHistory} from "react-router-dom";
import Title from '../components/Title';
import FadeIn from "react-fade-in";
import $ from "jquery";
import './Signup.css';
import {useInView} from "react-intersection-observer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const SignupLenus = (props) => {
    const containerRef = React.useRef();
    const script = document.createElement('script');
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    const [under18, setUnder18] = React.useState(true);
    const title = "PH.GAINS"
    const subTitle = "ONLINE COACHING";
    const history = useHistory();
    const formEng = "https://lenus.io/api/v1/f54058d2-d801-11eb-9074-3ba1f5a33075/form/lead-form";
    const formNor = "https://lenus.io/api/v1/f54058d2-d801-11eb-9074-3ba1f5a33075/form/lead-form"; //same at the moment

    React.useEffect(() => {
        if(containerRef != undefined && containerRef.current != undefined && !scriptLoaded) {
            setScriptLoaded(true);
            script.src = isEnglish() ? formEng : formNor;
            script.async = true;
            containerRef.current.appendChild(script);
            //addEventListener();
        }

        if(scriptLoaded) {
            const ageTimeout = setTimeout(addAgeEventListener, 3000);
            const locationTimeout = setTimeout(addLocationEventListener, 7000);
        }
    }, [scriptLoaded]); //[currentStep, error, resultText, props.term]);

    const goTo = (link) => {
        history.push(link);
    }

    const addAgeEventListener = () => {
        //alert(document.querySelectorAll('span').filter( n => n.textContent == '16-17')[0].textContent);
        var hey = document.querySelectorAll('span');
        for (var i = 0; i < hey.length; i++) {
            if (hey[i].textContent == '16-17')
            {
                //alert(hey[i].textContent);
                hey[i].addEventListener('click', () => goTo('/offers'));
            }
        }
    }

    const addLocationEventListener = () => {
        const countries = ["IN", "ZA", "PK", "MA", "DZ", "BR", "RO", "RU", "IR", "AR", "TN", "LB", "ID", "NG", "PL", "CO", "BD", "AL", "IQ", "AF", "RS", "NP", "MK", "TZ", "SI", "EC", "LK", "AS", "SO", "OM", "JO", "GT", "SD", "GH", "MZ", "AO", "LV", "ZW", "ET", "VE", "HN", "NA", "BH", "UZ", "CR", "LT", "UG", "UY", "TT", "MU", "AD", "AI", "DO", "TH", "SN", "PA", "AX", "LY", "ZM", "VN", "JM", "HT", "PS", "AW", "CU", "SY", "AZ", "CW", "CD", "BO", "PY", "MW", "AM", "BZ", "KZ", "CI", "SV", "NI", "SR", "BM", "ER", "LR", "MM", "PG", "BJ", "BW", "BI", "KH", "CM", "GQ", "SZ", "PF", "GP", "GY", "MO", "ML", "MR", "MN", "MS", "NU", "RW", "SX", "SJ", "TJ", "GM", "LS", "CG", "BT", "BQ", "BV", "BF", "CV", "CF", "TD", "DM", "GF", "TF", "GA", "GD", "GN", "GW", "MH", "MQ", "YT", "FM", "NR", "NC", "NE", "WS", "SL", "SS", "TO", "TM", "EH", "YE", "KI", "KG", "LA", "ST", "CL"];
        const countrySelector = document.getElementsByName("country")[0];
        if (countrySelector) {
            countrySelector.addEventListener("change", (e) =>
            {
                if (countries.some((r) => e.target.value.includes(r))) {
                    window.onbeforeunload = undefined;
                    //window.location.href = "https://phgainscoaching.co.uk/offers";
                    goTo('/offers');
                }
            });
        }
    }

    const isEnglish = () => {
        // if(window.location.pathname ==  "/n") {
        //     return false;
        // }
        //
        // if(window.location.pathname ==  "/e") {
        //     return true;
        // }

        if(localStorage.getItem("language") != null) {
            if(localStorage.getItem("language") == "N") {
                return false;
            }
        }

        return true;
    }

    return (<FadeIn transitionDuration="1000" delay={0} >
        <div className="signup-div"  style={{margin: '0 auto', justfyContent: 'center'}}>
            {/*{load()}*/}
            <div className="center-content opage">
                <div className="remove-margin signup-space-top">
                    <Title title={title}/>
                    {/*<FadeIn transitionDuration="600" delay="500" ><Title title={title}/></FadeIn>*/}
                    <FadeIn  transitionDuration="1400" delay="500" ><h2 className="formsubheader">
                        {subTitle}
                    </h2></FadeIn></div>
                <div ref={containerRef} className="center-content signup-container" style={{color: 'white !important'}}></div>
                {/*<div className="contact-footer center-content"><p>Will be in contact &nbsp;&nbsp;*/}
                {/*    <FontAwesomeIcon icon={faWhatsapp}/>&nbsp;/&nbsp;*/}
                {/*    <MessageIcon />&nbsp;/&nbsp;*/}
                {/*    <FontAwesomeIcon icon={faInstagram}/>&nbsp;/&nbsp;*/}
                {/*    <AttachEmailIcon />*/}
                {/*</p></div>*/}
            </div>
            {under18 ? <small><p className="my-theme-center-text">
                {isEnglish() ? "Are you 16/17 years old? " : "Er du 16/17 år gammel? "}
                <i><a href={"/offers"} className="my-theme-seconday-text">
                    {isEnglish() ? "Click here for available packages" : "Klikk her for tilgjengelige tilbud"}
                </a></i></p></small> : null}
            <small><p className="my-theme-center-text">
                {isEnglish() ? "Don't have WhatsApp? " : "Har ikke WhatsApp? "}
                <i><a className="my-theme-color" href="https://www.whatsapp.com/download">
                    {isEnglish() ? " Download here " : " Last ned her "}<FontAwesomeIcon icon={faWhatsapp}/></a></i>
            </p></small>
            <small><p className="my-theme-center-text"><a className="process-link" href="/coaching/#process">
                {isEnglish() ? "Joining process details" : "Joining prosessen i detalj"}
            </a></p></small>
        </div></FadeIn>);
};
export default SignupLenus;

