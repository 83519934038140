import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import FadeIn from "react-fade-in";

const CookiePolicy = () => {
    const title = "Cookie Policy";

    React.useEffect(() => {
    }, [])

    const sectionBody = () => <div className="separate-page">
        <FadeIn transitionDuration="400" visible={true}><h1 className="formheader" >
            Cookie Policy</h1></FadeIn>
        <FadeIn transitionDuration="800">
        <h4 className="formsubheader">What Are Cookies</h4>
        <p>Most professional websites use cookies, which are tiny files that are downloaded to your computer, to improve your user experience.
        </p>
        <h4 className="formsubheader">How We Use Cookies</h4>
        <p>We don't currently use any cookies for the operation of the functionality of this site,
            but our third party payment gateway does.</p>
        <h4 className="formsubheader">Disabling Cookies</h4>
        <ul style={{color: "white"}}>
            <li>You can disable the use of cookies by changing settings in your browser (see your browser Help for this).</li>
            <li>This will affect the payment processing on this website,
                and will likely affect the functionality of many other sites you visit, therefore it is recommended that you keep cookies enabled.</li>
        </ul>
        <h4 className="formsubheader">The Cookies We Set</h4>
        <p>We don't currently set any cookies.</p>
        <h4 className="formsubheader">Third Party Cookies</h4>
        <p>The payment gateway sets a number of cookies to enable processing of card payments.</p>
        </FadeIn>
    </div>;

    return (sectionBody());
};
export default CookiePolicy;


