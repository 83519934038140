import React from "react";

const TopLogo = props => {
    React.useEffect(() => {
    }, [])

    return (<React.Fragment>
        <div className="text-image-container">
            <img
                style={{marginTop: '-10px'}}
                src="./images/ph_gains_logo_path.svg"
                className="d-inline-block align-top my-theme logo-img"
                alt="Logo"
            />{' '}
            {/*<div className="logo-centered logo-text">P.H</div>*/}
        </div>
    </React.Fragment>);
};

export default TopLogo;