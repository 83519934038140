import React, {useMemo} from 'react';
import FadeIn from "react-fade-in";
import './ResponsiveGallerty.css';
import {useInView} from "react-intersection-observer";
import ImageCompare from "./ImageCompare";
const baguetteBox = require('baguettebox.js');

const ResponsiveGallery = (props) => {
    const { ref, inView, entry } = useInView({/* Optional options */ threshold: 0, });
    const background =  `Fitness has always been a part of my life in one way or another.
                        At an early age I partook in athletics, played football & basketball, and start using the
                        gym as soon as I was old enough.
                        Straight away I fell in love with pushing my body to its natural limits, motivated by the
                        results I saw along the way.
                        After finding myself in the gym almost every day for over 10 years, I decided to take my passion
                        for fitness to the next level by qualifying as a level 3 PT.
                        Since then I have never looked back! I love training people from all walks of life and joining
                        them on their journey to reach their fitness goals and beyond.
                        That to me is just as rewarding to me as reaching my own!`;

    const mission =  `To help people reach their fitness goals and become the best version possible of themselves.
                        Most people don't know how far they can go until they start their journey, and experience the
                        progress and the motivation that comes with it, I have been there!
                        I want to help you unlock your fitness potential, you might just surprise yourself with the
                        levels you can reach with the right coaching,
                        whether your goal is building muscle, toning, increasing your general fitness, or all the
                        above!`;

    React.useEffect(() => {
        galleryScript();
    }, []);

    const galleryScript = () => {
        // window.addEventListener("load", function () {
        //     baguetteBox.run(".gallery");
        // });

        window.addEventListener("load", function () {
            baguetteBox.run(".gallery", {
                captions: true,
                buttons: false,
                animation: "fadeIn",
            });
        });
    };

    return (
        <FadeIn transitionDuration="200" delay={0} visible={inView ? true : false}>
            <div ref={ref} style={{margin: '0 auto', justfyContent: 'center'}}>
                <div className="new-page-section" style={{paddingLeft: '2vw', paddingRight: '2vw', color: 'black'}}>
                    <main>

                        <div className="gallery_title">
                            <h1>Design Collections</h1>
                        </div>

                        <article className='gallery'>
                            <a href="https://images.pexels.com/photos/2356059/pexels-photo-2356059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                               data-caption='The great thing about reaching the top of the mountain is realising that there’s space for more than one person. And you’re now in the prime position to help others up.'
                               className='item' title='Hay Bales'>
                                <div className="img-container">
                                    <ImageCompare before="/images/after1.png" after="/images/before1.png"/>
                                <img
                                    src="https://images.pexels.com/photos/2356059/pexels-photo-2356059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="first image" />
                                </div>
                            </a>
                            <a href="https://images.pexels.com/photos/814499/pexels-photo-814499.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                               data-caption='A lake is a landscape’s most beautiful and expressive feature. It is Earth’s eye; looking into which the beholder measures the depth of his own nature.'
                               className='item' title='Lake'><div className="img-container">
                                <img
                                    src="https://images.pexels.com/photos/814499/pexels-photo-814499.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="second image" /></div>
                            </a>
                            <a href="https://images.pexels.com/photos/2775196/pexels-photo-2775196.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                               data-caption='The human desire to obtain more is a sieve that can never be filled with all the water from the world’s oceans'
                               title='Canyon' className='item'><div className="img-container">
                                <img
                                    src="https://images.pexels.com/photos/2775196/pexels-photo-2775196.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="third image" /></div>
                            </a>
                            <a href="https://images.pexels.com/photos/20045/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                               data-caption="Knowing trees, I understand the meaning of patience. Knowing grass, I can appreciate persistence."
                               title='Iceberg' className='item'><div className="img-container">
                                <img
                                    src="https://images.pexels.com/photos/20045/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="fourth image" /></div>
                            </a>
                            <a href="https://images.pexels.com/photos/6004828/pexels-photo-6004828.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                               data-caption="Life is like a train track, full of possibilities and happiness"
                               title='Desert' className='item'><div className="img-container">
                                <img
                                    src="https://images.pexels.com/photos/6004828/pexels-photo-6004828.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="fifth image" /></div>
                            </a>
                            <a href="https://images.pexels.com/photos/1533060/pexels-photo-1533060.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                               data-caption='Hell is a swamp, to me - not as something fiery, but as something dank, moist, and wet'
                               title='Fall' className='item'><div className="img-container">
                                <img
                                    src="https://images.pexels.com/photos/1533060/pexels-photo-1533060.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="sixth image" /></div>
                            </a>
                            <a href="https://images.pexels.com/photos/20045/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                               data-caption="Knowing trees, I understand the meaning of patience. Knowing grass, I can appreciate persistence."
                               title='Iceberg' className='item'><div className="img-container">
                                <img
                                    src="https://images.pexels.com/photos/20045/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="fourth image" /></div>
                            </a>
                            <a href="https://images.pexels.com/photos/814499/pexels-photo-814499.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                               data-caption='A lake is a landscape’s most beautiful and expressive feature. It is Earth’s eye; looking into which the beholder measures the depth of his own nature.'
                               className='item' title='Lake'><div className="img-container">
                                <img
                                    src="https://images.pexels.com/photos/814499/pexels-photo-814499.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="second image" /></div>
                            </a>
                        </article>
                    </main>
                </div>
            </div></FadeIn>
    );
};
export default ResponsiveGallery;
