import React, {useMemo} from 'react';
import Paper from '@material-ui/core/Paper';
import FadeIn from "react-fade-in";
import VideoEmbed from "../components/VideoEmbed";
import VerticalLinearStepper from "./Process";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BorderColor from '@material-ui/icons/BorderColor';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import '../components/Contact.css';
import './SectionApp.css';
import './SectionAppPhone.scss';
import {useInView} from "react-intersection-observer";
import $ from "jquery";
import SubjectIcon from "@material-ui/icons/Subject";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { Toast } from 'react-bootstrap';
import { Viewport } from 'react-is-in-viewport';
import Fade from "@mui/material/Fade";
import ImageCompare from "../components/ImageCompare";

const SectionApp = (props) => {
    const { ref, inView, entry } = useInView({/* Optional options */ threshold: 0, });
    const textList = ["Detailed progress tracker","Fitness video lessons & direct chat with me","Workout & exercise tracker","Detailed meal plan & macro breakdown"];
    const [bg, setBg] = React.useState("/images/len1.jpg");
    const [text, setText] = React.useState(textList[0]);
    const [i, setI] = React.useState(1);

    const features =  `See the short app walk-through below`;

    React.useEffect(() => {

    }, []);

    const setIndex = () => {
        var currentI = i;
        if(currentI == 4) {
            currentI = 0;
        }

        setText(textList[currentI]);
        currentI = currentI + 1;
        setBg("/images/len" + currentI + ".jpg");
        setI(currentI);
    };

    const animatePhone = () => {
        $('.agrid').addClass('toggle');
    };
    const unAnimatePhone = () => {
        $('.agrid').removeClass('toggle');
    };
    const toggleView = () => {
        var grid = document.querySelector('.agrid');
        grid.classList.toggle('toggle');
    };

    return (
        <FadeIn transitionDuration="200" delay={0} visible={inView ? true : false}>
            <div ref={ref} style={{margin: '0 auto', justfyContent: 'center', textAlign: 'center'}}>
                <div className="new-page-section" style={{paddingLeft: '2vw', paddingRight: '2vw', color: 'white'}}>
                    <a name="app"></a>
                    <FadeIn transitionDuration="400" visible={inView ? true : false}><h1 className="formheader">APP</h1></FadeIn>
                    <FadeIn transitionDuration="600" visible={inView ? true : false}><h2 className="formsubheader">FEATURES</h2></FadeIn>
                    <div className="wrapper">
                        <div className="ext-buttons"></div>
                        <div className="case">
                            <div className="glass"></div>
                            <div className="button"></div>
                        </div>
                    </div>
                    <Fade in={true} mountOnEnter unmountOnExit timeout={4000}>
                        <div className="app-table center-content" style={{position: 'relative', justifyContent: 'center',}}>
                            <div className="app-table-cell"><img alt="google play" className="app-img" src="./images/googleplay.png"/></div>
                            <div className="app-table-cell"><img alt="app store" className="app-img" src="./images/appstore.png"/></div>
                        </div>
                    </Fade>
                    <FadeIn transitionDuration="300" visible={inView ? true : false}>
                        <div>{text}</div>
                        <div className="force-height center-content">
                            <main className="acontent center-content">
                                <div className="wrapper">

                                    <div className="phone_body"></div>
                                    <div className="screen" style={{backgroundImage: `url(${bg})`}}>
                                    </div>

                                    <div className="speaker"></div>
                                    <div onClick={() => {setIndex()}} className="home"><ArrowForwardIosIcon className="next-arrow"/></div>

                                    <div className="side_buttons"></div>

                                </div>
                            </main>
                        </div>
                        <Viewport type="overlap" onEnter={animatePhone} onLeave={unAnimatePhone} />
                    </FadeIn>
                    <FadeIn transitionDuration="800" visible={inView ? true : false}>
                        <p style={{marginTop: '250px'}}>{features}</p>
                    </FadeIn>
                </div>
                <div className="centered-hori2 button-margin-bottom">
                    <FadeIn transitionDuration="2000" delay="800" visible={inView ? true : false}>
                        <iframe src="https://drive.google.com/file/d/1cYoVgJ9e8dakBjKg4Axo8G5DY07XwwXZ/preview" width="640" height="480" allow="autoplay"></iframe>
                        {/*<VideoEmbed*/} (https://jumpshare.com/blog/how-to-embed-google-drive-video/)
                        {/*    width=""*/}
                        {/*    playing={false}*/}
                        {/*    loop={false}*/}
                        {/*    videoUrl="https://drive.google.com/uc?export=download&id=1cYoVgJ9e8dakBjKg4Axo8G5DY07XwwXZ" />*/}
                    </FadeIn>
                </div>
                <FadeIn transitionDuration="1000" delay="1000" visible={inView ? true : false}>
                    {/*<Button style={{marginTop: '10px'}} onClick={() => props.scrollCallback("Terms", false)}*/}
                    {/*        variant="contained" sx={{ mt: 1, mr: 1 }}>TERMS <SubjectIcon /></Button>*/}
                </FadeIn>
            </div></FadeIn>
    );
};
export default SectionApp;
