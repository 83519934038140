import React from 'react';
import {Button, Container, Row, Col} from 'react-bootstrap';
import NavMenu from './NavMenu';
import HomeLogo from "./HomeLogo";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import SimpleReactFooter from "simple-react-footer";
import FadeIn from "react-fade-in";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt} from "@fortawesome/free-solid-svg-icons";
import {faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import HomeStars from "./HomeStars"
import {useHistory, withRouter} from "react-router-dom";
import CookieModal from './CookieModal';
import Footer from './Footer';

const Layout = (props) => {
    const [showModal, setShowModal] = React.useState(false);
    const [modalShown, setModalShown] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
    }, []);

    const cookieCon = () => {
        return (!modalShown && props.showFooter) && <CookieModal toggleCookies={props.toggleCookies} />
    };

    return (<div style={{height: '100%'}}
                 className={(props.location.pathname == '/' ||
                     props.location.pathname == '/#home' ||
                     props.location.pathname == '')? "banner-image" : "banner-image-light"}>
        <Container fluid style={{minHeight: `${props.location.pathname == '/workout'?
                (window.innerWidth < 690 ? '350px' : '650px'):'0px'}`}}>
            {cookieCon()}
            <Row>
                <NavMenu cartCount={props.cartCount} products={props.products} searchCallback={props.searchCallback}/>
                <HomeLogo />

            </Row>
            <Row className="no-flexing">
                {props.children}
                {/*<HomeLogo />*/}
            </Row>

        </Container>
        {(history.location.hash != "" || history.location.pathname != "/" || props.showFooter)
            ? <FadeIn className="bg-second" delay={1000}><Footer/></FadeIn> : null}
    </div>
    );
};

export default withRouter(Layout);
