import React, {useMemo} from 'react';
import Paper from '@material-ui/core/Paper';
import FadeIn from "react-fade-in";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import './Contact.css';
import {useInView} from "react-intersection-observer";

const Terms = (props) => {
    const header = "TERMS";
    const header2 = "& CONDITIONS";
    const p1 = "Please read the following section outlining the conditions for my online coaching:"
    const p2 =  `By accepting these terms, you agree that you can access digital content in the form of workouts,
training programmes and diet plans before your normal 14-day withdrawal period has expired.`;

    const p3 =  `Therefore, you expressly waive your right of withdrawal from the moment you get access your digital
content. Until you get access, you have the right of withdrawal according to the general rules of the
Consumer Contract Act.`;

    const p4 =  `Notice of withdrawal must be given digitally by email to [coach email] and must be received by us
before you have accessed the Digital Content. In the subject field, please write "Withdrawal". You can
also choose to use the standard withdrawal form, which you can download `;

    const p5 =  `Clients are expected to complete their commitment period in full.
    The remainder of the plan duration can be altered if required. 
     In exceptional circumstances the plan can be cancelled during the commitment period with 1 month notice.`;

    React.useEffect(() => {
    }, []);

    return (<React.Fragment>
        <FadeIn transitionDuration="200" delay={0}>
            <div className="separate-page">
                <div>
                        <FadeIn transitionDuration="400" visible={true}><h1 className="formheader" >
                            {header}</h1></FadeIn>
                        <FadeIn transitionDuration="400" visible={true}><h2 className="formsubheader">
                            {header2}</h2></FadeIn>
                        <FadeIn transitionDuration="400">
                            <p>{p1}</p>
                        </FadeIn>
                        <FadeIn transitionDuration="800">
                            <p>{p2}</p>
                        </FadeIn>
                        <FadeIn transitionDuration="1000">
                            <p>{p3}</p>
                        </FadeIn>
                        <FadeIn transitionDuration="1200">
                            <p>{p4}<a href="/Right_to_Withdrawal.docx" download>here</a>.</p>
                        </FadeIn>
                        <FadeIn transitionDuration="1400">
                            <p>{p5}</p>
                        </FadeIn>
                </div>
                <div></div>
            </div></FadeIn></React.Fragment>
    );
};
export default Terms;
