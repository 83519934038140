import React, {useMemo} from 'react';
import Paper from '@material-ui/core/Paper';
import FadeIn from "react-fade-in";
import './Signup.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const OfferForm = (props) => {
    const header = "CURRENT OFFERS";
    const header2 = "PACKAGES";
    const containerRef = React.useRef();
    const script = document.createElement('script');
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    const title = "PH.GAINS"
    const subTitle = "ONLINE COACHING";
    const bundleFormScript = "https://eu.lenus.io/api/v1/f54058d2-d801-11eb-9074-3ba1f5a33075/form/bundle-form";

    React.useEffect(() => {
        if(containerRef != undefined && containerRef.current != undefined && !scriptLoaded) {
            setScriptLoaded(true);
            script.src = bundleFormScript;
            script.async = true;
            containerRef.current.appendChild(script);
        }

        if(scriptLoaded) {
            // const myTimeout = setTimeout(loadActions, 5000);
        }
    }, [scriptLoaded]);

    return (
        <FadeIn transitionDuration="200" delay={0}>
            <div className="separate-page">
                <div>
                        <FadeIn transitionDuration="400" visible={true}><h1 className="formheader" >
                            {header}</h1></FadeIn>
                        <FadeIn transitionDuration="400" visible={true}><h2 className="formsubheader">
                            {header2}</h2></FadeIn>
                        <div className="signup-div"  style={{margin: '0 auto', justfyContent: 'center'}}>
                            {/*{load()}*/}
                            <div className="center-content opage">
                                <div ref={containerRef} className="center-content signup-container" style={{color: 'white !important'}}></div>
                            </div>
                            <small><p className="my-theme-center-text">
                                For personalised coaching &nbsp;
                                <i><a className="my-theme-color" href="/register">
                                    complete the for here.</a></i>
                            </p></small>
                        </div>
                </div>
                <div></div>
            </div>
        </FadeIn>
    );
};
export default OfferForm;
