import React, {useMemo} from 'react';
import Paper from '@material-ui/core/Paper';
import FadeIn from "react-fade-in";
import VideoEmbed from "../components/VideoEmbed";
import VerticalLinearStepper from "./Process";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BorderColor from '@material-ui/icons/BorderColor';
import Button from '@material-ui/core/Button';
import '../components/Contact.css';
import {useInView} from "react-intersection-observer";
import {IconButton} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import $ from "jquery";
import ThreeDPhone from "../components/ThreeDPhone";
import { Viewport } from 'react-is-in-viewport';

const SectionServices = (props) => {
    const { ref, inView, entry } = useInView({/* Optional options */ threshold: 0, });
    const intro =  `My one-to-one online coaching is specifically tailored to you, considering your goals, physical ability, age, dietary requirements etc.
                        Whatever your goals are, my job is to provide the most optimal workout plans & meal plans while keeping you on track & motivated, to ensure that you reach them!`;
    const offer =  `My coaching includes the following:`;

    const offer1 =  `Weekly check-ins with me over video call to monitor your progress and make sure you're on track.`
    const offer2 =  `Workout plan(s) tailored to your goals and abilities, both accessible through the app and in PDF form.`
    const offer3 =  `Bespoke meal plan(s) meeting nutritious targets (macros, calories etc.) set for you considering any restrictions or preferences you may have, also provided through the app.`
    const offer4 =  `Support from me at anytime through the chat function in the app or on the phone/Whatsapp/Telegram etc.`
    const offer5 =  `Regular video lessons on relevant fitness topics provided through the app.`
    const offer6 =  `No restrictions on plan modifications, for example if you lose gym access and need a home workout plan.`
    const process =  `The following steps outline the process of joining my online coaching:`;
    const features =  `See the short app walk-through below`;

    React.useEffect(() => {
    }, []);

    const animatePhone = () => {
        // if(window.innerWidth < 1000)
            $('.phone').addClass('phone-hover');
    };
    const unAnimatePhone = () => {
        // if(window.innerWidth < 1000)
            $('.phone').removeClass('phone-hover');
    };

    return (
        <FadeIn transitionDuration="200" delay={0} visible={inView ? true : false}>
            <div ref={ref} style={{margin: '0 auto', justfyContent: 'center', textAlign: 'center'}}>
                <div className="new-page-section center-content page-text" style={{paddingLeft: '2vw', paddingRight: '2vw', color: 'white'}}>
                    <a name="coaching"></a>
                    <FadeIn transitionDuration="400" visible={inView ? true : false}><h1 className="formheader">COACHING</h1></FadeIn>
                  <FadeIn transitionDuration="600" visible={inView ? true : false}><h2 className="formsubheader">WHAT'S INCLUDED</h2></FadeIn>
                    <FadeIn transitionDuration="800" visible={inView ? true : false}>
                        {/*<p id="intro">{intro}</p>*/}
                        {/*<FadeIn transitionDuration="2000" delay="800">*/}
                        {/*    <ThreeDPhone gifUrl="https://drive.google.com/uc?export=download&id=1KYyXPRsgQ9eTpbfdg-VY86OYi2Hlxzcj"/>*/}
                        {/*</FadeIn>*/}
                        <Viewport type="overlap" onEnter={animatePhone} onLeave={unAnimatePhone} />
                        <p>{offer}</p>
                        <ul className="no-pad left_text" style={{color: 'black'}}>
                            <li><CheckCircleIcon color="primary"  class="tick-list"/> {offer1}</li>
                            <li><CheckCircleIcon color="primary" class="tick-list"/> {offer2}</li>
                            <li><CheckCircleIcon color="primary" class="tick-list"/> {offer3}</li>
                            <a name="process"></a>
                            <li><CheckCircleIcon color="primary" class="tick-list"/> {offer4}</li>
                            <li><CheckCircleIcon color="primary" class="tick-list"/> {offer5}</li>
                            <li><CheckCircleIcon color="primary" class="tick-list"/> {offer6}</li>
                        </ul>
                        <FadeIn transitionDuration="600" visible={inView ? true : false}><h2 className="formsubheader">HOW TO JOIN</h2></FadeIn>
                        <p>{process}</p>
                        <VerticalLinearStepper />
                    </FadeIn>
                </div>

            </div></FadeIn>
    );
};
export default SectionServices;
